<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#EFE6FD" />
    <path
      d="M18 17.4743C18 17.6176 17.9849 17.7292 17.8792 17.8407C17.7887 17.9363 17.6679 18 17.5321 18H16.0981C15.9623 18 15.8566 17.9522 15.7509 17.8407C15.6604 17.7451 15.6 17.6176 15.6 17.4743V15.913C15.6 15.7696 15.5547 15.6581 15.4642 15.5466C15.3736 15.451 15.2679 15.3873 15.1321 15.3873H8.89811C8.76226 15.3873 8.6566 15.435 8.56604 15.5466C8.47547 15.6422 8.43019 15.7696 8.43019 15.913V17.4743C8.43019 17.6176 8.38491 17.7292 8.27925 17.8407C8.17358 17.9522 8.06792 18 7.93208 18H6.49811C6.36226 18 6.2566 17.9522 6.15094 17.8407C6.04528 17.7292 6 17.6176 6 17.4743V11.5159C6 10.6078 6.15094 9.76348 6.46792 8.96691C6.78491 8.17034 7.20755 7.48529 7.75094 6.89583C8.29434 6.30637 8.9283 5.84436 9.65283 5.5098C10.3774 5.17525 11.1623 5 11.9925 5C12.8226 5 13.6075 5.17525 14.3321 5.5098C15.0566 5.84436 15.7057 6.30637 16.2491 6.89583C16.7925 7.48529 17.2151 8.17034 17.5321 8.96691C17.8491 9.76348 18 10.6078 18 11.5159V17.4743ZM15.6151 11.5797C15.6151 11.0061 15.5245 10.4645 15.3283 9.95466C15.1472 9.44485 14.8906 8.99877 14.5585 8.61642C14.2264 8.23407 13.8491 7.93137 13.4113 7.72426C12.9736 7.51716 12.5057 7.40564 12.0075 7.40564C11.5094 7.40564 11.0415 7.51716 10.6189 7.72426C10.1811 7.9473 9.80377 8.23407 9.4717 8.60049C9.13962 8.96691 8.88302 9.41299 8.70189 9.90686C8.52075 10.4167 8.41509 10.9424 8.41509 11.5159V12.5993C8.41509 12.7426 8.46038 12.8542 8.55094 12.9657C8.64151 13.0613 8.74717 13.125 8.88302 13.125H15.1321C15.2679 13.125 15.3736 13.0772 15.4642 12.9657C15.5547 12.8701 15.6 12.7426 15.6 12.5993V11.5637L15.6151 11.5797Z"
      fill="white"
    />
  </svg>
</template>
