<script setup>
import BetySpaceIcon from "@/components/bety/icon/BetySpaceIcon";

import SpaceMaze from "@/components/space/SpaceMaze.vue";
import SpaceTutorial from "@/components/space/SpaceTutorial.vue";
import SpaceHangeulPixel from "@/components/bety/space/SpaceHangeulPixel";
import SpaceHangeulPixelCustom from "@/components/bety/space/SpaceHangeulPixelCustom";
import SpaceMathMaze from "@/components/bety/space/SpaceMathMaze";
import SpaceComputeMaze from "@/components/bety/space/SpaceComputeMaze";

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "",
  },
  pageno: {
    type: Number,
    required: true,
    default: 0,
  },
});
</script>

<template>
  <div class="wrap">
    <space-tutorial v-if="props.type == 'tutorial'" />
    <space-maze v-if="props.type == 'maze_old'" />
    <space-hangeul-pixel v-if="props.type === 'pixel'" :pageno="props.pageno" />
    <space-hangeul-pixel-custom
      v-if="props.type === 'pixel_custom'"
      :pageno="props.pageno"
    />
    <space-math-maze v-if="props.type === 'maze_inc'" type="inc" />
    <space-math-maze v-if="props.type === 'maze_dec'" type="dec" />
    <space-compute-maze
      v-if="props.type === 'maze_compute_hand'"
      :pageno="props.pageno"
      action="hand"
    />
    <space-compute-maze
      v-if="props.type === 'maze_compute_face'"
      :pageno="props.pageno"
      action="face"
    />
    <space-compute-maze
      v-if="props.type === 'maze_compute_pose'"
      :pageno="props.pageno"
      action="pose"
    />

    <div class="title">
      <div class="title_icon">
        <bety-space-icon />
      </div>
      <div class="title_text">스페이스</div>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  position: absolute;
  top: 10px;
  left: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  color: var(--color-bety-white);
  font-size: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.title_icon {
  display: flex;
  align-items: center;

  width: 25px;
  height: 25px;
  margin-right: 7px;
}
</style>
