<template>
  <footer>
    <div class="footer font-label-large">
      <div class="container">
        <div class="footer_top">
          <div class="footer_top_left">
            <div class="footer_logo">
              <a href="https://rewond.com">
                <img
                  src="@/assets/home/footer_logo.png"
                  style="width: 100px; height: 15px"
                />
              </a>
            </div>
          </div>
          <div class="footer_top_right">
            <a href="https://junior.rewond.com">
              <img
                src="@/assets/home/betyjunior_logo.png"
                style="width: 34px; height: 34px"
              />
            </a>
            <a href="https://rewond.com/bety">
              <img
                src="@/assets/home/bety_logo.png"
                style="width: 34px; height: 34px"
              />
            </a>
          </div>
        </div>
        <div class="footer_middle">
          <div class="footer_middle_left">
            <span>(주)리원드 | </span>
            <span>대표자: 한승엽 | </span>
            <span>사업자번호: 789-86-02949 | </span
            ><span
              ><a
                href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7898602949"
                target="_blank"
                >사업자 정보 확인</a
              ></span
            >
            <br />
            <span>통신판매업: 2024-인천연수구-0624 | </span
            ><span>개인정보보호책임자: 한승엽 | </span
            ><span>이메일: support@rewond.com</span> <br /><span
              >전화번호: 010-4388-4008 |
            </span>
            <span
              >주소: 인천광역시 연수구 송도문화로 119, 글로벌스타트업캠퍼스
              B1006 S-3</span
            >
          </div>
        </div>
        <div class="footer_bottom">
          <div class="footer_bottom_left">
            <div>이용안내</div>
            <div>개인정보처리방침</div>
            <div>사업자 정보 확인</div>
          </div>
          <div class="footer_bottom_right">©REWOND. ALL RIGHTS RESERVED</div>
        </div>
        <!-- <div class="footer_left footer_info">
          <span>(주)리원드 | </span>
          <span>대표자: 한승엽 | </span>
          <span>사업자번호: 789-86-02949 | </span>
          <span
            ><a
              href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7898602949"
              target="_blank"
              >사업자 정보 확인</a
            ></span
          >
          <br />
          <span>통신판매업: 2024-인천연수구-0624 | </span
          ><span>개인정보보호책임자: 한승엽 | </span
          ><span>이메일: support@rewond.com</span>
          <br />
          <span>전화번호: 010-4388-4008 | </span>
          <span>주소: 인천광역시 연수구 갯벌로 12 미추홀타워 본관 1401호</span>
          <br />
          <span>©REWOND. ALL RIGHTS RESERVED</span>
        </div>
        <div class="footer_right footer_sns"></div> -->
      </div>
    </div>
  </footer>
  <!--  -->
</template>

<script setup></script>

<style scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
img:hover {
  cursor: pointer;
}
.container {
  width: 100%;
  height: 100%;
  max-width: var(--container-width);
  min-height: 250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}
.footer {
  background-color: #47248a;
  color: white;
  padding: 10px 20px;
  margin: 0 auto;
}

.footer_info {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
}

.footer_top {
  width: 100%;
  display: flex;
  align-items: center;
}

.footer_top_right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.footer_middle {
  display: flex;
  margin-top: 16px;
  margin-bottom: 40px;
}
.footer_middle_left {
  flex: 1;
}

.footer_bottom {
  border-top: 1px solid white;
  display: flex;
  padding: 16px 0;
}

.footer_bottom_left {
  display: flex;
  gap: 16px;
  flex: 1;
}

span a:hover {
  color: var(--color-bety-gray);
}

.font-label-large {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
</style>
