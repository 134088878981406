<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true,
    default: "bety",
  },
  title: {
    type: String,
    required: true,
    default: "Untitled",
  },
  text: {
    type: String,
    required: true,
    default: "No Text",
  },
});
</script>

<template>
  <div class="card-item">
    <div class="card-img">
      <img
        v-if="props.src === 'bety'"
        src="@/assets/home/program_bety.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'betyjunior'"
        src="@/assets/home/program_betyjunior.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a01'"
        src="@/assets/home/activity/activity01.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a02'"
        src="@/assets/home/activity/activity02.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a03'"
        src="@/assets/home/activity/activity03.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a04'"
        src="@/assets/home/activity/activity04.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a05'"
        src="@/assets/home/activity/activity05.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a06'"
        src="@/assets/home/activity/activity06.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a07'"
        src="@/assets/home/activity/activity07.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a08'"
        src="@/assets/home/activity/activity08.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a09'"
        src="@/assets/home/activity/activity09.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a10'"
        src="@/assets/home/activity/activity10.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a11'"
        src="@/assets/home/activity/activity11.png"
        class="card-img-src"
      />
      <img
        v-if="props.src === 'a12'"
        src="@/assets/home/activity/activity12.png"
        class="card-img-src"
      />
    </div>
    <div class="card-content">
      <div class="card-content-title">
        <p class="font-title-large">{{ props.title }}</p>
      </div>
      <div class="card-content-text">
        <p class="font-body-medium" style="color: #242424">
          {{ props.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-item {
  width: 320px;
  height: 440px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease; /* 크기 변화를 부드럽게 애니메이션 */
}
.card-item:hover {
  transform: scale(1.05); /* 카드 크기를 1.05배 확대 */
  cursor: pointer;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.card-img {
  width: 100%;
  height: 320px;
  background-color: #47248a;
  border-radius: 10px 10px 0 0;
}
.card-img-src {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.card-content {
  width: 100%;
  height: 120px;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 10px 10px;
  padding: 16px;
  box-sizing: border-box;
}
.card-content-title {
}

.card-content-text {
  margin-top: 6px;
  white-space: pre-line;
}

.font-title-large {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
}
.font-body-medium {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
</style>
