<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();

const helpRoute = ref("");

onMounted(() => {
  let routeMajor = router.currentRoute.value.params.major || "";
  let routeMinor = router.currentRoute.value.params.minor || "";

  console.log("[HELP_DIALOG]");
  console.log(routeMajor);
  console.log(routeMinor);

  if (routeMajor === "hangeul") {
    switch (routeMinor) {
      case "represent":
        helpRoute.value = "hangeul-represent-pose";
        break;
      case "represent_hand":
        helpRoute.value = "hangeul-represent-hand";
        break;
      case "pixel":
      case "pixel_custom":
        helpRoute.value = "hangeul-pixel-pose";
        break;
      case "wordselect":
        helpRoute.value = "hangeul-wordselect-hand";
        break;
      default:
        helpRoute.value = "";
        break;
    }
  } else if (routeMajor === "math") {
    switch (routeMinor) {
      case "order":
        helpRoute.value = "math-order-hand";
        break;
      case "math_represent":
        helpRoute.value = "math-represent-hand";
        break;
      case "maze_inc":
        helpRoute.value = "math-maze-pose";
        break;
      case "maze_dec":
        helpRoute.value = "math-maze-pose";
        break;
      default:
        helpRoute.value = "";
        break;
    }
  } else if (routeMajor === "coding") {
    switch (routeMinor) {
      case "maze_compute_pose":
        helpRoute.value = "coding-maze-pose";
        break;
      case "maze_compute_face":
        helpRoute.value = "coding-maze-face";
        break;
      case "maze_compute_hand":
        helpRoute.value = "coding-maze-hand";
        break;
      default:
        helpRoute.value = "";
        break;
    }
  } else {
    helpRoute.value = "";
  }
});

const closeHelp = () => {
  store.commit("Bety/setHelpOpen", false);
};
</script>
<template>
  <div class="wrap_help" @click.stop="closeHelp">
    <div class="help_dialog">
      <img
        v-if="helpRoute == 'hangeul-represent-pose'"
        class="img_help"
        src="@/assets/bety_help/help_hangeul_represent_pose.png"
      />
      <img
        v-if="helpRoute == 'hangeul-represent-hand'"
        class="img_help"
        src="@/assets/bety_help/help_hangeul_represent_hand.png"
      />
      <img
        v-if="helpRoute == 'hangeul-pixel-pose'"
        class="img_help"
        src="@/assets/bety_help/help_hangeul_pixel_pose.png"
      />
      <img
        v-if="helpRoute == 'hangeul-wordselect-hand'"
        class="img_help"
        src="@/assets/bety_help/help_hangeul_wordselect_hand.png"
      />
      <img
        v-if="helpRoute == 'math-order-hand'"
        class="img_help"
        src="@/assets/bety_help/help_math_order_hand.png"
      />
      <img
        v-if="helpRoute == 'math-represent-hand'"
        class="img_help"
        src="@/assets/bety_help/help_math_represent_hand.png"
      />
      <img
        v-if="helpRoute == 'math-maze-pose'"
        class="img_help"
        src="@/assets/bety_help/help_math_maze_pose.png"
      />
      <img
        v-if="helpRoute == 'coding-maze-pose'"
        class="img_help"
        src="@/assets/bety_help/help_coding_maze_pose.png"
      />
      <img
        v-if="helpRoute == 'coding-maze-face'"
        class="img_help"
        src="@/assets/bety_help/help_coding_maze_face.png"
      />
      <img
        v-if="helpRoute == 'coding-maze-hand'"
        class="img_help"
        src="@/assets/bety_help/help_coding_maze_hand.png"
      />
    </div>
  </div>
</template>

<style scoped>
.wrap_help {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
}

.help_dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
}

.img_help {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
