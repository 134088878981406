<script setup>
import { ref } from "vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import SupportFaqQIcon from "@/components/home/icon/SupportFaqQIcon.vue";
import SupportFaqDownIcon from "@/components/home/icon/SupportFaqDownIcon.vue";
import SupportFaqAIcon from "@/components/home/icon/SupportFaqAIcon.vue";

const faqList = ref([
  {
    question: "회원가입은 어떻게 하나요?",
    answer:
      "상단의 메뉴의 [사람 아이콘]을 누르고 회원가입을 진행해주실 바랍니다.",
    selected: false,
  },
  {
    question: "학교에서 구입하려면 어떻게 하나요?",
    answer:
      "상단 메뉴의 [문의사항] > [문의하기]에서 해당 학교와 인원 수 등 정보를 기입하시면 조속히 답변드리겠습니다.",
    selected: false,
  },
  {
    question: "베티에서 사용할 때 오류가 발생해요",
    answer:
      "직원들이 지속 모니터링하고 있으며 조속한 해결을 하고있습니다. 불편을 드려서 죄송합니다.",
    selected: false,
  },
]);
const clickQuestion = (idx) => {
  faqList.value[idx].selected = !faqList.value[idx].selected;
};
</script>

<template>
  <the-header bg="#f6f1fe" />
  <main>
    <div class="container_">
      <div class="content">
        <div class="wrap">
          <div class="breadcrumb">
            <p class="font-title-small">홈 · 고객센터 · FAQ</p>
          </div>
          <div class="faq">
            <div class="headline">
              <p class="font-headline-large" style="text-align: center">
                { FAQ }
              </p>
            </div>
            <div class="table font-title-medium">
              <div class="table-header">
                전체 <span style="color: #47248a">{{ faqList.length }}</span
                >건
              </div>
              <div class="table-body">
                <div class="faq-list">
                  <div
                    class="faq-list-item"
                    v-for="(item, idx) in faqList"
                    :key="item.id"
                  >
                    <div
                      class="faq-list-item-question"
                      :class="{ 'item-selected': item.selected }"
                      @click="clickQuestion(idx)"
                    >
                      <div class="faq-list-item-question-icon">
                        <support-faq-q-icon />
                      </div>
                      <div class="faq-list-item-question-content">
                        {{ item.question }}
                      </div>
                      <div class="faq-list-item-question-down">
                        <support-faq-down-icon />
                      </div>
                    </div>
                    <div class="faq-list-item-answer" v-show="item.selected">
                      <div class="faq-list-item-answer-icon">
                        <support-faq-a-icon />
                      </div>
                      <div class="faq-list-item-answer-content font-body-large">
                        {{ item.answer }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <the-footer />
</template>

<style scoped>
main {
  width: 100vw;
}
.container_ {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  width: 100%;
  height: fit-content;
  background-color: white;
  color: #47248a; /*p800*/
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.wrap {
  width: 100%;
  max-width: var(--container-width);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box;
}

.breadcrumb {
  margin-top: 200px;
  width: 100%;
}

.headline {
  margin: 0 8px;
}

.table {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.table-header {
  width: 100%;
  height: 40px;
  color: black;
}

.table-body {
  width: 100%;
}
.faq-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #f6f1fe;
  border-bottom: 2px solid #f6f1fe;
}

.faq-list-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.faq-list-item-question {
  width: 100%;
  height: 80px;
  padding: 20px 0;
  color: black;
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid #f6f1fe;
  align-items: center;
}
.faq-list-item-question:hover {
  cursor: pointer;
}

.faq-list-item-question-icon {
  padding: 8px;
  box-sizing: border-box;
}

.faq-list-item-question-content {
  margin: 0 16px;
  box-sizing: border-box;
  flex: 1;
}

.faq-list-item-question-down {
  padding-right: 16px;
  box-sizing: border-box;
}

.item-selected {
  background-color: #f6f1fe;
  border-radius: 10px;
  border-top: none;
}

.faq-list-item-answer {
  width: 100%;
  padding: 20px 0;
  color: black;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

.faq-list-item-answer-icon {
  padding: 8px;
  box-sizing: border-box;
}

.faq-list-item-answer-content {
  margin: 0 16px;
  box-sizing: border-box;
  flex: 1;
}

.font-title-medium {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.font-title-small {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.font-display-medium {
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
}
.font-headline-large {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.font-headline-medium {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}
.font-headline-small {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.font-body-large {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
</style>
