<script setup>
import BetyPixelGrid from "@/components/bety/sprite/BetyPixelGrid";
import BetyPixelDot from "@/components/bety/sprite/BetyPixelDot";
import BetySprite from "@/components/bety/sprite/BetySprite";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();

const deriveHangeulFromId = (id) => {
  switch (id) {
    case 101:
      return "G";
  }
};

const deriveInfoFromHangeul = (hangeul) => {
  switch (hangeul) {
    case "G":
      return {
        gridRow: 5,
        gridCol: 7,
        gridData: [],
        difficult: false,
      };
  }
};

// const id = computed(() =>
//   props.pageno < 19 ? props.pageno + 101 : props.pageno + 201 - 19
// );
const pageNo = computed(() => {
  return store.state.Space.pageNumber - 1 < 19
    ? store.state.Space.pageNumber - 1 + 101
    : store.state.Space.pageNumber - 1 + 201 - 19;
});
watch(pageNo, () => {
  doRestart();
});
const hangeul = computed(() => deriveHangeulFromId(pageNo.value));

const mapData = computed(() => deriveInfoFromHangeul(hangeul.value));

const gridRow = ref(mapData.value.gridRow);
const gridCol = ref(mapData.value.gridCol);
// const difficult = ref(mapData.difficult);
const dotData = ref(mapData.value.gridData);
const gridCellSize = 80; //80px
const gridData = ref([]);
const betyElement = ref(null);
const betyX = ref(0);
const betyY = ref(0);
const fillRest = ref(2);
const stageSuccess = ref(false);
const stageFailed = ref(false);

const betyStyle = computed(() => {
  console.log("x: " + betyX.value);
  return `position:absolute; height:${gridCellSize - 10}px; left: ${
    betyX.value * gridCellSize + gridCellSize / 2
  }px; top: ${
    betyY.value * gridCellSize + gridCellSize / 2
  }px; transform:translate(-50%,-50%);
        transition: all 0.3s ease;`;
});
// function getRandomInt(max) {
//   return Math.floor(Math.random() * max);
// }

// function getRandomPair(x) {
//   const num1 = getRandomInt(x);
//   let num2 = getRandomInt(x);

//   // num1과 다른 값이 나올 때까지 다시 뽑기
//   while (num2 === num1) {
//     num2 = getRandomInt(x);
//   }

//   return [num1, num2];
// }

onMounted(() => {
  for (let i = 0; i < gridRow.value; i++) {
    for (let j = 0; j < gridCol.value; j++) {
      gridData.value.push({
        style: `position:absolute; top:${i * gridCellSize}px; left:${
          j * gridCellSize
        }px`,
        type: "none",
      });
    }
  }
  // for (const h of dotData.value) {
  //   gridData.value[h[0] * gridCol.value + h[1]].type = "filled";
  // }
  // let r = getRandomPair(dotData.value.length);
  // gridData.value[
  //   dotData.value[r[0]][0] * gridCol.value + dotData.value[r[0]][1]
  // ].type = "placehold";
  // gridData.value[
  //   dotData.value[r[1]][0] * gridCol.value + dotData.value[r[1]][1]
  // ].type = "placehold";
});

const doRestart = () => {
  stageSuccess.value = false;
  stageFailed.value = false;
  fillRest.value = 2;
  betyX.value = 0;
  betyY.value = 0;
  gridRow.value = mapData.value.gridRow;
  gridCol.value = mapData.value.gridCol;
  dotData.value = mapData.value.gridData;
  gridData.value = [];
  for (let i = 0; i < gridRow.value; i++) {
    for (let j = 0; j < gridCol.value; j++) {
      gridData.value.push({
        style: `position:absolute; top:${i * gridCellSize}px; left:${
          j * gridCellSize
        }px`,
        type: "none",
      });
    }
  }

  // for (const h of dotData.value) {
  //   gridData.value[h[0] * gridCol.value + h[1]].type = "filled";
  // }
  // let r = getRandomPair(dotData.value.length);
  // gridData.value[
  //   dotData.value[r[0]][0] * gridCol.value + dotData.value[r[0]][1]
  // ].type = "placehold";
  // gridData.value[
  //   dotData.value[r[1]][0] * gridCol.value + dotData.value[r[1]][1]
  // ].type = "placehold";
  store.commit("Block/clearBlock");
};

// watch(id, () => {
//   doRestart();
// });

const isPlaying = computed(() => store.state.Space.isPlaying);

watch(isPlaying, async (newValue) => {
  if (!newValue) return;
  for (let block of store.state.Block.block) {
    if (block == "left go") {
      if (betyX.value > 0) betyX.value -= 1;
      else break;
    }
    if (block == "right go") {
      if (betyX.value < gridCol.value - 1) betyX.value += 1;
      else break;
    }
    if (block == "up go") {
      if (betyY.value > 0) betyY.value -= 1;
      else break;
    }
    if (block == "down go") {
      if (betyY.value < gridRow.value - 1) betyY.value += 1;
      else break;
    }
    if (block == "shoot") {
      let pos = betyY.value * gridCol.value + betyX.value;
      if (gridData.value[pos].type === "none") {
        gridData.value[pos].type = "filled";
        fillRest.value = -1;
        break;
      } else if (gridData.value[pos].type === "placehold") {
        gridData.value[pos].type = "filled";
        fillRest.value--;
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  // if (fillRest.value === 0) {
  //   stageSuccess.value = true;
  // } else if (fillRest.value === -1) {
  //   stageFailed.value = true;
  // }
  store.commit("Block/clearBlock");
  store.commit("Space/stop");
});

const clickMsg = (value) => {
  if (value === "next") {
    let routeMajor = router.currentRoute.value.params.major || "";
    let routeMinor = router.currentRoute.value.params.minor || "";
    let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
    routePageno = isNaN(routePageno) ? 0 : routePageno;
    router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
    // location.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
    store.commit("Space/setPageNumber", routePageno + 1);
  } else if (value === "reload") {
    doRestart();
  }
};
</script>

<template>
  <div class="wrap">
    <div class="content">
      <div class="title">글자를 그려보아요</div>
      <div class="grid">
        <bety-pixel-grid :type="gridCol + 'x' + gridRow" />
        <div class="dots">
          <div
            class="dot"
            v-for="dot in gridData"
            :key="dot.id"
            :style="dot.style"
          >
            <bety-pixel-dot :type="dot.type" />
          </div>

          <!-- <div class="dot" style="position: absolute; left: 0; top: 0">
            <bety-pixel-dot type="none" />
          </div>
          <div class="dot" style="position: absolute; left: 80px; top: 0">
            <bety-pixel-dot type="filled" />
          </div>
          <div class="dot" style="position: absolute; left: 160px; top: 80px">
            <bety-pixel-dot type="filled" />
          </div> -->
        </div>
        <bety-sprite :style="betyStyle" ref="betyElement" />
      </div>
    </div>

    <div
      v-show="stageSuccess"
      class="message-box"
      @click.stop="clickMsg('next')"
    >
      성공!!
    </div>
    <div
      v-show="stageFailed"
      class="message-box"
      @click.stop="clickMsg('reload')"
    >
      다시 해볼까요?
    </div>
  </div>
</template>

<style scoped>
.message-box {
  display: flex;
  position: absolute;
  width: 300px;
  height: 50px;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  background-color: var(--color-bety-gray);
  border: 0;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.message-box:hover {
  background-color: var(--color-bety-white);
  cursor: pointer;
}
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  gap: 30px;
}
.title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
  font-size: 40px;
}

.grid {
  position: relative;
}
.dots {
  position: absolute;
  top: 1px;
  left: 2px;
}
</style>
