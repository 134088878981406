<script setup>
// import HeaderProfileIcon from "@/components/icon/HeaderProfileIcon";
import { ref } from "vue";
import axios from "@/api";

axios.defaults.withCredentials = true;

const props = defineProps({
  bg: {
    type: String,
    required: true,
    default: "transparent",
  },
});

let isLoggedIn = ref(false);

async function loggedin() {
  try {
    await axios.get(`/api/user/profile`);
    //const response = await axios.get(`/api/user/profile`);
    //console.log(response.data);
    isLoggedIn.value = true;
  } catch (error) {
    isLoggedIn.value = false;
    console.log(error);
  }
}

// async function logout() {
//   if (!isLoggedIn.value) return;
//   try {
//     const response = await axios.get(`/api/auth/logout`);
//     console.log(response.data);
//     console.log("Logout Success");
//     isLoggedIn.value = false;
//   } catch (error) {
//     console.log("Logout Failed");
//   }
// }

// async function clickLogout() {
//   await logout();
// }
// function downloadTablet() {
//   location.replace(
//     `https://drive.google.com/file/d/1hwiysTIATneviYsmLzZr_gbXXlN60d4T/view?usp=drive_link`
//   );
// }

loggedin();
</script>

<template>
  <header id="header" :style="`background-color:${props.bg}`">
    <div class="container container-desktop">
      <div class="header-left">
        <router-link to="/">
          <div class="brand_header">
            <img
              src="@/assets/home/header_logo.png"
              style="width: 202px; height: 30px"
            />
          </div>
        </router-link>
      </div>
      <div class="header-right font-title-medium">
        <nav role="navigation" class="header-nav">
          <router-link to="/company">
            <div class="nav-company nav-link">회사소개</div>
          </router-link>
          <router-link to="/program">
            <div class="nav-program nav-link">프로그램</div>
          </router-link>
          <router-link to="/support">
            <div class="nav-support nav-link">문의사항</div>
          </router-link>
          <!-- <router-link to="/price">
            <div class="nav-price nav-link">요금</div>
          </router-link> -->
          <div class="header-dropdown">
            <div class="dropdown-grid font-title-small">
              <router-link to="/company/vision">
                <div class="dropdown-grid-item">리원드</div>
              </router-link>
              <router-link to="/program/overview">
                <div class="dropdown-grid-item">프로그램 소개</div>
              </router-link>
              <router-link to="/support/faq">
                <div class="dropdown-grid-item">FAQ</div>
              </router-link>

              <router-link to="/company/history">
                <div class="dropdown-grid-item">연혁</div>
              </router-link>
              <router-link to="/program/activity">
                <div class="dropdown-grid-item">활동 내용</div>
              </router-link>
              <router-link to="/support/contact">
                <div class="dropdown-grid-item">문의하기</div>
              </router-link>

              <router-link to="/company/service">
                <div class="dropdown-grid-item">사업영역</div>
              </router-link>
              <router-link to="/program/price">
                <div class="dropdown-grid-item">요금제</div>
              </router-link>
              <!-- <router-link to="/support/contact"> -->
              <div class="dropdown-grid-item"></div>
              <!-- </router-link> -->

              <router-link to="/company/partner">
                <div class="dropdown-grid-item">파트너십</div>
              </router-link>
              <div class="dropdown-grid-item"></div>
              <div class="dropdown-grid-item"></div>
            </div>
          </div>
        </nav>
      </div>
      <!-- <div class="header-right-loggedin" v-if="isLoggedIn">
        <div class="logout" @click="clickLogout">로그아웃</div>
        <router-link to="/motion">
          <div class="profile"><header-profile-icon /></div>
        </router-link>
      </div>
      <div class="header-right" v-if="!isLoggedIn">
        <div class="tablet_download" @click="downloadTablet">태블릿용</div>
        <router-link to="/login">
          <div class="login">로그인</div>
        </router-link>

        <router-link to="/register">
          <div class="register">
            <button class="register-button">회원가입</button>
          </div>
        </router-link>
      </div> -->
    </div>
  </header>
</template>

<style scoped>
#header {
  /* background-color: var(--color-bety-primary); */
  width: 100%;
  height: 100px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

#header .container {
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: var(--container-width);
  min-height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.header-left {
  align-items: center;
  display: flex;
  color: black;
}
.header-right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.header-right-loggedin {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header-nav {
  display: flex;
  position: relative;
}
.header-dropdown {
  position: absolute;
  top: 100px;
  width: 100%;
  background-color: white;
  opacity: 0;
  height: 0;

  border: 2px solid #efe6fd;
  border-top: 0;
  border-radius: 0 0 35px 35px;
  box-sizing: border-box;

  transition: opacity 0.3s ease;
}
.header-nav:hover .header-dropdown {
  opacity: 1;
  height: fit-content;
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 가로로 3개 */
  grid-template-rows: repeat(4, 1fr); /* 세로로 4개 */
  margin: 8px auto; /* 중앙 정렬 */
}

/* 기본 그리드 아이템 스타일 */
.dropdown-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  text-decoration: none; /* router-link의 기본 스타일 제거 */
  color: black;
  transition: color 0.3s ease; /* hover 애니메이션 */
}

/* hover 시 보라색 배경 */
.dropdown-grid-item:hover {
  color: #5900d9;
  cursor: pointer;
}

.nav-link {
  display: flex;
  color: #47248a;
  width: 150px;
  padding: 38px 0;
  justify-content: center;
  box-sizing: border-box;
}
.nav-link {
  cursor: pointer;
}
.brand_header {
  padding: 0;
  width: 202px;
}

.login {
  display: flex;
  align-items: center;
  width: 60px;
}
.register {
  display: flex;
  align-items: center;
  width: 80px;
}
.logout {
  display: flex;
  align-items: center;
  width: 70px;
}
.logout:hover {
  cursor: pointer;
}
.profile {
  display: flex;
  align-items: center;
}
.register-button {
  width: 100%;
  background-color: #f35050;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}
.tablet_download {
  width: 70px;
  background-color: var(--color-bety-complementary);
  color: black;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.font-title-medium {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.font-title-small {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}
</style>
