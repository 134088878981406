<template>
  <the-header />
  <main>
    <div class="container_">
      <div class="content">
        <div class="wrap">
          <div class="breadcrumb">
            <p class="font-title-small">홈 · 프로그램 · 활동내용</p>
          </div>
          <div class="vision">
            <div class="headline">
              <p class="font-headline-large" style="text-align: center">
                { 활동내용 }
              </p>
            </div>
            <div class="text">
              <p class="font-headline-small" style="text-align: center"></p>
            </div>
            <div class="program">
              <div class="grid-card">
                <the-card
                  v-for="content in cardContent"
                  :key="content.id"
                  :src="content.src"
                  :title="content.title"
                  :text="content.text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <the-footer />
</template>

<script setup>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheCard from "@/components/home/TheCard.vue";

const cardContent = [
  { src: "a01", title: "[2024년] 디지털새싹캠프 활동", text: "2024. 08." },
  { src: "a02", title: "[2024년] 디지털새싹캠프 활동", text: "2024. 07." },
  { src: "a03", title: "[2024년] 경인교육대학교 연수", text: "2024. 04." },
  { src: "a04", title: "[2024년] 인천초등학교 실습", text: "2024. 05." },
  { src: "a05", title: "[2024년] 인천초등학교 실습", text: "2024. 05." },
  { src: "a06", title: "[2024년] 인천초등학교 실습", text: "2024. 05." },
  { src: "a07", title: "[2024년] 인천초등학교 실습", text: "2024. 05." },
  { src: "a08", title: "[2024년] 베타 테스트 및 시연", text: "2024. 02." },
  { src: "a09", title: "[2024년] 교사 연수", text: "2024. 04." },
  { src: "a10", title: "[2024년] 베타 테스트 및 시연", text: "2024. 01." },
  { src: "a11", title: "[2024년] 디지털새싹캠프 활동", text: "2024. 04." },
  { src: "a12", title: "[2024년] 인천초등학교 활용", text: "2024. 05." },
];
</script>

<style scoped>
main {
  width: 100vw;
}
.container_ {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  width: 100%;
  height: 2300px;
  background-color: #f6f1fe;
  color: #47248a; /*p800*/
  display: flex;
  flex-direction: column;
}

.wrap {
  width: 100%;
  max-width: var(--container-width);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box;
}

.breadcrumb {
  margin-top: 150px;
  width: 100%;
}

.vision {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 36px;
  box-sizing: border-box;
}

.headline {
  margin: 0 8px;
}

.text {
  margin-top: 24px;
}
.program {
  width: 100%;
  display: flex;
  justify-content: center;
}

.grid-card {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 가로로 3개 */
  grid-template-rows: repeat(4, 1fr); /* 세로로 4개 */
  gap: 32px;
}
/* .grid-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

.font-title-small {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.font-display-medium {
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
}
.font-headline-large {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.font-headline-medium {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}
.font-headline-small {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.font-body-large {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
</style>
