import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/home/HomeView";
import CompanyVisionView from "@/views/home/CompanyVisionView";
import CompanyServiceView from "@/views/home/CompanyServiceView";
import CompanyPartnerView from "@/views/home/CompanyPartnerView";
import CompanyHistoryView from "@/views/home/CompanyHistoryView";
import ProgramOverviewView from "@/views/home/ProgramOverviewView";
import ProgramActivityView from "@/views/home/ProgramActivityView";
import ProgramPriceView from "@/views/home/ProgramPriceView";
import SupportNoticeView from "@/views/home/SupportNoticeView";
import SupportFaqView from "@/views/home/SupportFaqView";
import SupportContactView from "@/views/home/SupportContactView";

import ProductView from "@/views/ProductView";
import AboutView from "@/views/tomb/AboutView";
import LoginView from "@/views/LoginView";
import RegisterView from "@/views/RegisterView";
import PolicyView from "@/views/PolicyView";
import TemporaryView from "@/views/tomb/TemporaryView";
import ChecklistView from "@/views/ChecklistView";
//import BlocklyView from "@/views/BlocklyView";
//import BlockView from "@/views/BlockView";
import MotionView from "@/views/MotionView";
import HandView from "@/views/HandView";
import FaceView from "@/views/FaceView";
import CookView from "@/views/CookView";
import TutorialView from "@/views/TutorialView";
import InputView from "@/views/InputView";
import ErrorView from "@/views/ErrorView";
import BetyView from "@/views/BetyView";
import BetyCodeView from "@/views/BetyCodeView";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/company",
    redirect: "/company/vision",
  },
  {
    path: "/company/vision",
    name: "CompanyVision",
    component: CompanyVisionView,
  },
  {
    path: "/company/service",
    name: "CompanyService",
    component: CompanyServiceView,
  },
  {
    path: "/company/partner",
    name: "CompanyPartner",
    component: CompanyPartnerView,
  },
  {
    path: "/company/history",
    name: "CompanyHistory",
    component: CompanyHistoryView,
  },

  {
    path: "/program",
    redirect: "/program/overview",
  },
  {
    path: "/program/overview",
    name: "ProgramOverview",
    component: ProgramOverviewView,
  },
  {
    path: "/program/activity",
    name: "ProgramActivity",
    component: ProgramActivityView,
  },
  {
    path: "/program/price",
    name: "ProgramPrice",
    component: ProgramPriceView,
  },

  {
    path: "/support",
    redirect: "/support/notice",
  },
  {
    path: "/support/notice",
    name: "SupportNotice",
    component: SupportNoticeView,
  },
  {
    path: "/support/faq",
    name: "SupportFaq",
    component: SupportFaqView,
  },
  {
    path: "/support/contact",
    name: "SupportContact",
    component: SupportContactView,
  },

  {
    path: "/product ",
    name: "Product",
    component: ProductView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
  },
  {
    path: "/policy/term",
    name: "PolicyTerm",
    component: PolicyView,
    props: { view: "term" },
  },
  {
    path: "/policy/privacy",
    name: "PolicyPrivacy",
    component: PolicyView,
    props: { view: "privacy" },
  },
  {
    path: "/checklist",
    name: "Checklist",
    component: ChecklistView,
  },
  {
    path: "/input",
    name: "Input",
    component: InputView,
  },
  // {
  //   path: "/block",
  //   name: "Block",
  //   component: BlockView,
  // },
  // {
  //   path: "/blockly",
  //   name: "Blockly",
  //   component: BlocklyView,
  // },
  {
    path: "/motion",
    name: "Motion",
    component: MotionView,
  },
  {
    path: "/hand",
    name: "Hand",
    component: HandView,
  },
  {
    path: "/face",
    name: "Face",
    component: FaceView,
  },
  {
    path: "/cook",
    name: "Cook",
    component: CookView,
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: TutorialView,
  },
  {
    path: "/temp",
    name: "Temporary",
    component: TemporaryView,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: ErrorView,
  },
  {
    path: "/bety",
    redirect: "/bety/hangeul/represent/1",
  },
  {
    path: "/bety/:major/:minor/:pageno",
    name: "Bety",
    component: BetyView,
    props: true,
  },
  {
    path: "/betycode",
    name: "BetyCode",
    component: BetyCodeView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
