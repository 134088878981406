<script setup>
import BetyPagenavigatorButton from "@/components/bety/BetyPagenavigatorButton";
import { ref, computed, onMounted, onUpdated, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();

const emit = defineEmits(["nav"]);
const emitEvent = (value) => {
  emit("nav", value);
};

const pageRestricted = computed(() => store.state.User.completePage);

const setNavigate = (value) => {
  let routeMajor = router.currentRoute.value.params.major || "";
  let routeMinor = router.currentRoute.value.params.minor || "";
  let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  routePageno = isNaN(routePageno) ? 0 : routePageno;
  let flag = false;

  if (value === "next") {
    if (routePageno < fullPageNo.value) {
      if (
        routeMinor === "maze_compute_pose" ||
        routeMinor === "maze_compute_face" ||
        routeMinor === "maze_compute_hand"
      ) {
        console.log(pageRestricted.value[routeMinor]);
        if (routePageno <= pageRestricted.value[routeMinor]) {
          routePageno++;
          flag = true;
        }
      } else {
        routePageno++;
        flag = true;
      }
    }
  } else if (value === "previous") {
    if (routePageno > 1) {
      routePageno--;
      flag = true;
    }
  }
  presentPageNo.value = routePageno;
  store.commit("Space/setPageNumber", routePageno);
  // router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno}`);

  // TODO: it must be fixed!!!!!
  if (routeMinor === "wordselect")
    location.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno}`);
  if (flag) router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno}`);
  emitEvent(value);
};

onMounted(() => {
  let routeMinor = router.currentRoute.value.params.minor || "";
  let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  routePageno = isNaN(routePageno) ? 0 : routePageno;
  store.commit("Space/setPageNumber", routePageno);

  if (routeMinor == "maze_compute_pose") fullPageNo.value = 42;
  if (routeMinor == "maze_compute_face") fullPageNo.value = 42;
  if (routeMinor == "maze_compute_hand") fullPageNo.value = 42;
  if (routeMinor == "math_represent") fullPageNo.value = 4;
  presentPageNo.value = routePageno;
});
const pageNo = computed(() => store.state.Space.pageNumber);
watch(pageNo, (newValue) => {
  presentPageNo.value = newValue;
});

onUpdated(() => {
  // let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  // routePageno = isNaN(routePageno) ? 0 : routePageno;
  // presentPageNo.value = routePageno;
});

const previousHover = ref(false);
const nextHover = ref(false);
const presentPageNo = ref(1);
const fullPageNo = ref(40);

const pageIndicator = computed(() => {
  return presentPageNo.value + " / " + fullPageNo.value;
});

const setHover = (elem, value) => {
  if (elem === "previous") {
    previousHover.value = value;
  } else if (elem === "next") {
    nextHover.value = value;
  } else {
    console.warn("[BetyPagenavigator] Error");
  }
};
</script>

<template>
  <div class="wrap">
    <div
      class="previous"
      @mouseover="setHover('previous', true)"
      @mouseout="setHover('previous', false)"
      @click.stop="setNavigate('previous')"
    >
      <bety-pagenavigator-button type="previous" :hover="previousHover" />
    </div>
    <div class="indicator">{{ pageIndicator }}</div>

    <div
      class="next"
      @mouseover="setHover('next', true)"
      @mouseout="setHover('next', false)"
      @click.stop="setNavigate('next')"
    >
      <bety-pagenavigator-button type="next" :hover="nextHover" />
    </div>
  </div>
</template>

<style scoped>
.wrap {
  width: 160px;
  height: 55px;
  color: var(--color-bety-white);
  font-size: 20px;
  background-color: var(--color-bety-primary);
  border: 2px solid var(--color-bety-secondary);
  border-radius: 50px;
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.2));
  display: flex;
  align-items: center;
  justify-content: center;
}
.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
